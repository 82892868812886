export const US_STATE_LIST = [
  {
    value: "Alaska",
    label: "Alaska",
  },
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "Arkansas",
    label: "Arkansas",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Arizona",
    label: "Arizona",
  },
  {
    value: "California",
    label: "California",
  },
  {
    value: "Colorado",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    label: "Connecticut",
  },
  {
    value: "District of Columbia",
    label: "District of Columbia",
  },
  {
    value: "Delaware",
    label: "Delaware",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "Iowa",
    label: "Iowa",
  },
  {
    value: "Idaho",
    label: "Idaho",
  },
  {
    value: "Illinois",
    label: "Illinois",
  },
  {
    value: "Indiana",
    label: "Indiana",
  },
  {
    value: "Kansas",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    label: "Louisiana",
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
  },
  {
    value: "Maryland",
    label: "Maryland",
  },
  {
    value: "Maine",
    label: "Maine",
  },
  {
    value: "Michigan",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    label: "Minnesota",
  },
  {
    value: "Missouri",
    label: "Missouri",
  },
  {
    value: "Mississippi",
    label: "Mississippi",
  },
  {
    value: "Montana",
    label: "Montana",
  },
  {
    value: "North Carolina",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    label: "North Dakota",
  },
  {
    value: "Nebraska",
    label: "Nebraska",
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    label: "New Mexico",
  },
  {
    value: "Nevada",
    label: "Nevada",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "Ohio",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
  },
  {
    value: "South Carolina",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    label: "Tennessee",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Utah",
    label: "Utah",
  },
  {
    value: "Virginia",
    label: "Virginia",
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
  },
  {
    value: "Vermont",
    label: "Vermont",
  },
  {
    value: "Washington",
    label: "Washington",
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
  },
  {
    value: "West Virginia",
    label: "West Virginia",
  },
  {
    value: "Wyoming",
    label: "Wyoming",
  },
];

export const CITIZENSHIP_STATUS_OPTIONS = [
  {
    value: "CITIZEN",
    label: "U.S Citizen",
  },
  {
    value: "RESIDENT",
    label: "U.S Resident",
  },
  {
    value: "NON_RESIDENT",
    label: "non-Resident",
  },
];

export const COUNTRY_LIST = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Algeria",
    label: "Algeria",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Antigua & Deps",
    label: "Antigua & Deps",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Bosnia Herzegovina",
    label: "Bosnia Herzegovina",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "Brunei",
    label: "Brunei",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Burkina",
    label: "Burkina",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Central African Rep",
    label: "Central African Rep",
  },
  {
    value: "Chad",
    label: "Chad",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Comoros",
    label: "Comoros",
  },
  {
    value: "Congo",
    label: "Congo",
  },
  {
    value: "Congo {Democratic Rep}",
    label: "Congo {Democratic Rep}",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "East Timor",
    label: "East Timor",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Iran",
    label: "Iran",
  },
  {
    value: "Iraq",
    label: "Iraq",
  },
  {
    value: "Ireland {Republic}",
    label: "Ireland {Republic}",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Ivory Coast",
    label: "Ivory Coast",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Korea North",
    label: "Korea North",
  },
  {
    value: "Korea South",
    label: "Korea South",
  },
  {
    value: "Kosovo",
    label: "Kosovo",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Micronesia",
    label: "Micronesia",
  },
  {
    value: "Moldova",
    label: "Moldova",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Myanmar, {Burma}",
    label: "Myanmar, {Burma}",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Russian Federation",
    label: "Russian Federation",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "St Kitts & Nevis",
    label: "St Kitts & Nevis",
  },
  {
    value: "St Lucia",
    label: "St Lucia",
  },
  {
    value: "Saint Vincent & the Grenadines",
    label: "Saint Vincent & the Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Sao Tome & Principe",
    label: "Sao Tome & Principe",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "South Sudan",
    label: "South Sudan",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Suriname",
    label: "Suriname",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Syria",
    label: "Syria",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Trinidad & Tobago",
    label: "Trinidad & Tobago",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Vatican City",
    label: "Vatican City",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];

export const accountValidationStepsBaseList = [
  /*{
    title: "Terms and Conditions",
    description:
      "We need to make sure you are ok with our terms and conditions",
    percent: 0,
  },*/
  {
    title: "Personal Information",
    description: "We want to know more about you",
    percent: 0,
  },
  {
    title: "Phone Verification",
    description:
      "Provide a valid phone number to use it as a two factor authentication method",
    percent: 0,
  },
  {
    title: "Address Information",
    description: "We want to know more where you live",
    percent: 0,
  },
  {
    title: "Accreditation Information",
    description: "We want to know more about your investor profile",
    percent: 0,
  },
  {
    title: "Income Information",
    description: "We want to know more about your income",
    percent: 0,
  },
  {
    title: "Country of Citizenship",
    description: "We need informations about your country of citizenship",
    percent: 0,
  },
  {
    title: "Document Upload and Validation",
    description: "We need some documents to validate that you are you",
    percent: 0,
  },
  {
    title: "Awaiting Verification",
    description: "Please wait while we verify the documents you've sent",
    percent: 0,
  },
];

// Enums

// Appliction Status
export const APPLICATION_STATUS_APPROVED = "APPROVED";
export const APPLICATION_STATUS_PENDING = "PENDING";
export const APPLICATION_STATUS_REJECTED = "REJECTED";
export const APPLICATION_STATUS_REQUIRES_UPDATE = "REQUIRES_UPDATE";

// Douments Types
export const DOCUMENT_TYPE_DRIVERS_LICENSE = "DRIVERS_LICENSE";
export const DOCUMENT_TYPE_PASSPORT = "PASSPORT";
export const DOCUMENT_TYPE_STATE_ID = "STATE_ID";

// Document Orientation
export const DOCUMENT_ORIENTATION_FRONT = "FRONT";
export const DOCUMENT_ORIENTATION_BACK = "BACK";

// Onboarding Status
export const ONBOARDING_STATUS_NOT_ONBOARDED = "NOT_ONBOARDED";
export const ONBOARDING_STATUS_ONBOARDED_NO_VERIFICATION =
  "ONBOARDED_NO_VERIFICATION";
export const ONBOARDING_STATUS_VERIFICATION_FAILED = "VERIFICATION_FAILED";
export const ONBOARDING_STATUS_PENDING = "PENDING";
export const ONBOARDING_STATUS_ONBOARDED_WITH_ERROR = "ONBOARDED_WITH_ERROR";
export const ONBOARDING_STATUS_ONBOARDED_NO_ACCREDITATION =
  "ONBOARDED_NO_ACCREDITATION";

// Account Activity
export const ACTIVITY_LOGIN = "LOGIN";
export const ACTIVITY_PASSWORD_RESET = "PASSWORD_RESET";
export const ACTIVITY_ACCOUNT_CREATION = "ACCOUNT_CREATION";
export const ACTIVITY_OTHER_THINGS_TO_ADD = "OTHER_THINGS_TO_ADD";

// API endpoints

// Applications
export const API_ENDPOINT_APPLICATION_CREATE = "applications/create";
export const API_ENDPOINT_APPLICATION_READ = "applications/read/"; // {param: id}
export const API_ENDPOINT_APPLICATION_UPDATE = "/admin/statusupdate/"; // {param: id}
export const API_ENDPOINT_APPLICATION_DELETE = "applications/delete/"; // {param: id}
export const API_ENDPOINT_APPLICATION_SEARCH = "applications/search";
export const API_ENDPOINT_APPLICATION_LIST = "applications/list";

// Authentication

export const API_ENDPOINT_AUTHENTICATION_LOGIN = "user/login";
export const API_ENDPOINT_AUTHENTICATION_LOGOUT = "/logout";
export const API_ENDPOINT_AUTHENTICATION_REGISTER = "user/register";

// Authentication

export const API_ENDPOINT_USER_INFO = "user/me";
export const API_ENDPOINT_USER_LOGOUT = "user/logout";
export const API_ENDPOINT_USER_UPDATE_PROFILE = "user/update-profile";
export const API_ENDPOINT_USER_UPDATE_PASSWORD = "user/password-update";

// Documents
export const API_ENDPOINT_DOCUMENT_CREATE = "documents/create";
export const API_ENDPOINT_DOCUMENT_LIST = "documents/list";

// Documents
export const API_ENDPOINT_COUNTRIES_LIST = "countries/list";

// Accreditation status
export const ACCREDITED_BY_INCOME = "ACCREDITED_BY_INCOME";
export const ASSOCIATED_WITH_BROKER_DEALER = "ASSOCIATED_WITH_BROKER_DEALER";
export const NOT_ACCREDITED = "NOT_ACCREDITED";

// Admin routes
export const API_ENDPOINT_ADMIN_AUTHENTICATION_LOGIN = "admin/login";
export const API_ENDPOINT_ADMIN_LIST_USERS = "user/list";

export const accreditationTexts = [
  { id: "ACCREDITED_BY_INCOME", text: "Accredited investor" },
  {
    id: "ASSOCIATED_WITH_BROKER_DEALER",
    text: "Associated with a broke dealer",
  },
  { id: "NOT_ACCREDITED", text: "Not accredited" },
];
