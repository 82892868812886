export const AUTH_FAILED_REQUEST = "AUTH_FAILED_REQUEST";
export const FAILED_REQUEST = "FAILED_REQUEST";
export const LOADING_REQUEST = "AUTH_LOADING_REQUEST";

export const LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";

export const LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";

// User onboard flow 
export const ACTION_CREATE_APPLICATION = "ACTION_CREATE_APPLICATION";
export const ACTION_UPDATE_USER_PROFILE = "ACTION_UPDATE_USER_PROFILE";
