import { notification } from "antd";
import history from "@/utils/history";
import codeMessage from "./codeMessage";
import { API_ENDPOINT_AUTHENTICATION_LOGOUT } from "@/shared/constants";
import { deleteCookie } from "@/auth/cookie";
import {
  ACCESS_TOKEN_NAME,
  AUTH_DATA_COOKIE,
  USER_ONBOARDING_CURRENT_STEP,
} from "@/config/serverApiConfig";

const errorHandler = (error, emptyResult = null) => {
  const { response } = error;
  if (!response) {
    console.log("NO RESPONSE");
    // notification.config({
    //   duration: 20,
    // });
    // notification.error({
    //   message: "No internet connection",
    //   description: "Cannot connect to the server, Check your internet network",
    // });
    return {
      success: false,
      result: emptyResult,
      message: "Cannot connect to the server, Check your internet network",
    };
  } else if (response && response.status) {
    let message = response.data && response.data?.message;
    let errorText = message || codeMessage[response.status];
    if (response?.data?.msg) {
      errorText = response?.data?.msg;
    }
    console.log("error text:", errorText);
    const { status } = response;
    notification.config({
      duration: 20,
    });
    notification.error({
      message: `Request error ${status}`,
      description: errorText,
    });
    if (error.response.data.jwtExpired) {
      deleteCookie(AUTH_DATA_COOKIE);
      deleteCookie(ACCESS_TOKEN_NAME);
      deleteCookie(USER_ONBOARDING_CURRENT_STEP);
      history.push("/login");
    }
    return response.data;
  } else {
    notification.config({
      duration: 10,
    });
    notification.error({
      message: "Unknown Error",
      description: "An unknown error occurred in the app, please try again. ",
    });
    return {
      success: false,
      result: emptyResult,
      message: "An unknown error occurred in the app, please try again. ",
    };
  }
};

export default errorHandler;
